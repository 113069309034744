import React from "react"
import PropTypes from "prop-types"
import Heading from "../../atoms/Heading"

const WorkBox = ({ title, highlight, lead, list, url, children }) => {
  return (
    <div className="mt-8">
      <div className="-mx-4 md:mx-0">
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          className="block relative group"
        >
          <div className="">{children}</div>
          <div className=" transition-opacity duration-300 ease-in-out absolute inset-0 bg-dark bg-opacity-80 text-beige text-base lg:text-[20px] font-extrabold flex justify-center items-center opacity-0 group-hover:opacity-100">
            View on Behance
          </div>
        </a>
      </div>
      <div className="mt-6">
        <Heading variant="h3" classes="font-extrabold mb-2">
          {title}
        </Heading>
        <p className="mb-4 lg:w-3/4">
          <span className="font-extrabold">{highlight}</span> {lead}
        </p>
        <ul className="flex flex-wrap text-xs">
          {list.map((item, index) => {
            return (
              <li
                key={index}
                className='after:content-["/"] after:inline-block after:mx-2 last:after:hidden'
              >
                <strong className="font-normal">{item}</strong>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

WorkBox.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  highlight: PropTypes.string,
  lead: PropTypes.string.isRequired,
  list: PropTypes.array,
  children: PropTypes.node.isRequired,
}

WorkBox.defaultProps = {
  title: ``,
  url: ``,
  highlight: ``,
  lead: ``,
  list: ``,
  children: ``,
}

export default WorkBox
