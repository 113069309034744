import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HomeHero from "../components/organisms/HomeHero"
import ServicesSection from "../components/organisms/ServicesSection"
import Brands from "../components/organisms/Brands"
import Works from "../components/organisms/Works"
import Testimonials from "../components/organisms/Testimonials"
import AuthorisedVendor from "../components/organisms/AuthorisedVendor"
import QuoteSection from "../components/organisms/QuoteSection"

const IndexPage = () => (
  <Layout headerBg="bg-beige !absolute top-0 left-0 w-full">
    <Seo
      title="Peak11. Product Design and Custom Software Development"
      description="Peak11 is a mobile and web development company that provides custom software, 
      UX/UI product design, full-stack engineering, and exceptional service."
    />
    <HomeHero />
    <ServicesSection
      header="Have all your bases covered"
      subHeader="Services"
      lead="With a vast experience in building digital products from scratch or revamping existing ones 
      we support companies and startups at every stage of their product life cycle – starting with 
      research, concept, design, prototype, user tests, development, deployment and iterative improvements."
    />
    <Works
      header="Tread along our most recently fashioned paths"
      subHeader="Works"
    />
    <Testimonials header="Your journey with Peak11" subHeader="Testimonials" />
    <Brands />
    <AuthorisedVendor
      header="We are listed on the top review sites"
      subHeader="Verified company"
    ></AuthorisedVendor>
    <QuoteSection />
  </Layout>
)

export default IndexPage
