import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import QuoteIcon from "../../../images/icons/quote.svg"

const QuoteSection = () => {
  return (
    <section className=" py-8 md:py-14 bg-white">
      <div className="max-w-screen-xl mx-auto px-4">
        <div className="flex flex-wrap items-center -mx-4">
          <div className="w-full md:w-1/2 px-4">
            <div className="lg:pr-24">
              <div className="aspect-w-1 aspect-h-1">
                <StaticImage
                  src="../../../images/quote.jpg"
                  quality={100}
                  alt="Quote"
                  placeholder="Hektar Nektar"
                />
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-4">
            <div className="mt-10 md:mt-0">
              <div className="text-primary mb-8 md:mb-16">
                <QuoteIcon width="56" height="56" className="fill-current" />
              </div>
              <h3 className="text-dark text-2xl lg:text-[32px] leading-tight">
                “We take pride in developing meaningful products that lead to
                groundbreaking advancement for businesses and improved user
                experience”.
              </h3>
              <div className="mt-10 text-beige-150 font-extrabold">
                Peak11 Team
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default QuoteSection
