import React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import Heading from "../../atoms/Heading"
import SubHeader from "../../atoms/SubHeader"
import WorkBox from "../../molecules/WorkBox"

const Works = ({ header, subHeader }) => {
  return (
    <section className="py-8 md:py-20 bg-beige" id="works">
      <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
        <SubHeader label={subHeader} />
        <Heading variant="h2">{header}</Heading>
        <div className="flex flex-wrap -mx-4 lg:-mx-6">
          <div className="px-4 lg:px-6 w-full md:w-1/2">
            <WorkBox
              title="Tenko"
              url="https://www.behance.net/gallery/141501041/Tenko-3-Mobile-app-design"
              highlight="Let’s Get Out Together!"
              lead="Landing pages for shoppers and merchants along with realistic animated mobile app mockups.  "
              list={["Product Design", "Web Development", "Animations"]}
            >
              <StaticImage
                src="../../../images/works/tenko.jpg"
                layout="fullWidth"
                quality={100}
                alt="Tenko Mobile App Created by Peak11 Software House"
                placeholder="#0066FF"
              />
            </WorkBox>
          </div>
          <div className="px-4 lg:px-6 w-full md:w-1/2">
            <WorkBox
              title="OnTick"
              url="https://www.behance.net/gallery/157214715/OnTick-Split-and-settle-expenses-mobile-app"
              highlight="Split and Settle Your Bills."
              lead="Mobile app designed for young people to keep track of the money you owe and you’re owed."
              list={["Product Discovery", "Product Design", "Illustrations"]}
            >
              <StaticImage
                src="../../../images/works/ontick.jpg"
                layout="fullWidth"
                quality={100}
                alt="Mobile App OnTick Created by Peak11 Software House"
                placeholder="#0066FF"
              />
            </WorkBox>
          </div>
          <div className="px-4 lg:px-6 w-full md:w-1/2">
            <WorkBox
              title="Minute"
              url="https://www.behance.net/gallery/137872643/Minute-Handy-time-tracking-app-for-teams"
              highlight="Every Minute Counts!"
              lead="Minute is a time tracker, that turns your team on productivity while precisely scheduling and reporting your working hours."
              list={[
                "Product Design",
                "Web Development",
                "Support & Maintenance",
              ]}
            >
              <StaticImage
                src="../../../images/works/minute.jpg"
                layout="fullWidth"
                quality={100}
                alt="Minute Time Tracker App Created by Peak11 Software House"
                placeholder="#0066FF"
              />
            </WorkBox>
          </div>
          <div className="px-4 lg:px-6 w-full md:w-1/2">
            <WorkBox
              title="Hektar Nektar"
              url="https://www.behance.net/gallery/146688495/Projekt-2028-Platform-for-bee-ambassadors-and-business"
              highlight="Social and eCommerce platform."
              lead="Marketplace connecting beekeepers - bees, pollination services & bee sponsoring."
              list={[
                "Product Design",
                "Web Development",
                "Support & Maintenance",
              ]}
            >
              <StaticImage
                src="../../../images/works/hektar-nektar.jpg"
                layout="fullWidth"
                quality={100}
                alt="Hektar Nektar Social and eCommerce Platform Created by Peak11 Software House"
                placeholder="#0066FF"
              />
            </WorkBox>
          </div>
        </div>
      </div>
    </section>
  )
}

Works.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
}

Works.defaultProps = {
  header: ``,
  subHeader: ``,
}

export default Works
