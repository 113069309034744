import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Heading from "../../atoms/Heading"

const ServiceBox = ({ lead, icon, slug, justify, children }) => {
  return (
    <Link to={slug} className={`block max-w-[240px] group ${justify} `}>
      {icon}
      <Heading
        variant="h3"
        component="h4"
        classes="transition-colors duration-300 ease-in-out font-extrabold inline-block mt-4 max-w-[220px] border-b-[3px] border-transparent group-hover:border-primary"
      >
        {children}
      </Heading>
      <p className="mt-4 mb-4">{lead}</p>
    </Link>
  )
}

ServiceBox.propTypes = {
  children: PropTypes.node.isRequired,
  lead: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  icon: PropTypes.node,
}

ServiceBox.defaultProps = {
  children: ``,
  lead: ``,
  slug: ``,
  icon: ``,
}

export default ServiceBox
