import React from "react"
import PropTypes from "prop-types"

const SectionLead = ({ children }) => {
  return <div className="my-10 max-w-2xl text-base font-medium">{children}</div>
}

SectionLead.propTypes = {
  children: PropTypes.node.isRequired,
}

SectionLead.defaultProps = {
  children: ``,
}

export default SectionLead
