import React from "react"
import PropTypes from "prop-types"
import Heading from "../../atoms/Heading"
import SubHeader from "../../atoms/SubHeader"
import SectionLead from "../../atoms/SectionLead"
import ServiceBox from "../../molecules/ServiceBox"
import IdeationIcon from "../../../images/icons/ideation.svg"
import ProductDesignIcon from "../../../images/icons/product-design.svg"
import WebDevelopmentIcon from "../../../images/icons/web-development.svg"
import MobileDevelopmentIcon from "../../../images/icons/mobile-development.svg"
import AiIcon from "../../../images/icons/ai.svg"
import SupportIcon from "../../../images/icons/support.svg"

const ServicesSection = ({ header, subHeader, lead }) => {
  return (
    <section className="py-8 md:py-20 bg-white" id="services">
      <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
        <SubHeader label={subHeader} />
        <Heading variant="h2" classes="mb-8 md:mb-0">
          {header}
        </Heading>
        <SectionLead>{lead}</SectionLead>
        <div className="flex flex-wrap -mx-4">
          <div className={`w-full sm:w-1/2 md:w-1/3 px-4 md:mt-10`}>
            <ServiceBox
              justify="md:mr-auto"
              lead="Shaping and validation of product idea is a key phase that lays a solid foundation for digital product development."
              slug="/ideation"
              icon={
                <IdeationIcon
                  width="70px"
                  height="60px"
                  className="animated-icon"
                  title="Ideation & Research"
                />
              }
            >
              Ideation & Research
            </ServiceBox>
          </div>
          <div className={`w-full sm:w-1/2 md:w-1/3 px-4 md:mt-10`}>
            <ServiceBox
              justify="md:mx-auto"
              lead="UX/UI product design based on in-depth research that provides top user experience and achieves business goals."
              slug="/ux-ui-product-design"
              icon={
                <ProductDesignIcon
                  width="70px"
                  height="60px"
                  className="animated-icon"
                  title="Product Design"
                />
              }
            >
              Product Design
            </ServiceBox>
          </div>
          <div className={`w-full sm:w-1/2 md:w-1/3 px-4 md:mt-10`}>
            <ServiceBox
              justify="md:ml-auto"
              lead="We build insurmountable web applications using technologies such as Ruby, Ruby on Rails, Python, Django, and React.js."
              slug="/web-development"
              icon={
                <WebDevelopmentIcon
                  width="70px"
                  height="60px"
                  className="animated-icon"
                  title="Web Development"
                />
              }
            >
              Web Development
            </ServiceBox>
          </div>
          <div className={`w-full sm:w-1/2 md:w-1/3 px-4 md:mt-10`}>
            <ServiceBox
              justify="md:mr-auto"
              lead="We build high-quality and fault-tolerant Native iOS and Android, cross-platform Flutter and React Native based apps."
              slug="/mobile-development"
              icon={
                <MobileDevelopmentIcon
                  width="70px"
                  height="60px"
                  className="animated-icon"
                  title="Mobile Development"
                />
              }
            >
              Mobile Development
            </ServiceBox>
          </div>
          <div className={`w-full sm:w-1/2 md:w-1/3 px-4 md:mt-10`}>
            <ServiceBox
              justify="md:mx-auto"
              lead="With data science and artificial intelligence we turn business data into a competitive advantage and create market pioneers."
              slug="/data-science"
              icon={
                <AiIcon
                  width="70px"
                  height="60px"
                  className="animated-icon"
                  title="Artificial Intelligence & Data Science"
                />
              }
            >
              Artificial Intelligence <br /> & Data Science
            </ServiceBox>
          </div>
          <div className={`w-full sm:w-1/2 md:w-1/3 px-4 md:mt-10`}>
            <ServiceBox
              justify="md:ml-auto"
              lead="We grow launched products by iterative development, project management, quality assurance and top-level IT support."
              slug="/support-maintenance"
              icon={
                <SupportIcon
                  width="70px"
                  height="60px"
                  className="animated-icon"
                  title="Support & Maintenance"
                />
              }
            >
              Support <br /> & Maintenance
            </ServiceBox>
          </div>
        </div>
      </div>
    </section>
  )
}

ServicesSection.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
}

ServicesSection.defaultProps = {
  header: ``,
  subHeader: ``,
}

export default ServicesSection
