import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../../atoms/Button"
import ElevenIcon from "../../../images/icons/eleven.svg"

const HomeHero = () => {
  return (
    <section className="bg-transparent pt-24 md:pt-40 pb-8 md:pb-20 relative">
      <StaticImage
        src="../../../images/home-hero-bg.png"
        quality={100}
        alt="PEAK11"
        placeholder=""
        className="w-full h-full !absolute inset-0 object-cover"
      />
      <div className="relative z-10">
        <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
          <h1 className="text-hero/m lg:text-hero font-light mt-0.5">
            Reach <br className="block xs:hidden" />
            <span className="hero-underline">
              the next <br className="block xs:hidden" /> peak
            </span>{" "}
            <br className="" />
            of digital experience
          </h1>
          <div className="max-w-[760px] md:text-3xl">
            <div className="mt-10 font-medium leading-[1.3]">
              Peak11 is a mobile and web development agency that provides custom
              software, product design, full stack engineering, and exceptional
              service.
            </div>
          </div>
          <div className="flex justify-between items-end md:text-3xl mt-8 md:mt-20">
            <Button variant="contained" fullWidthMobile href="/contact">
              Let's talk
            </Button>
            <div className="relative">
              <ElevenIcon
                width="154px"
                height="187px"
                className="flex-shrink-0 hidden md:block absolute bottom-0 right-0"
                title="Peak11 Software House"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeHero
